import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'

const DworOlesinPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO
        title="Olesin / zabytkowy dwór
"
      />
      <ReturnToPrevPage to={'/gminy/debe-wielkie'}>
        Materiały dotyczące gminy Dębe Wielkie
      </ReturnToPrevPage>
      <Header1>Olesin / zabytkowy dwór</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Około roku 1910 r. lekarz Stanisław Leopold Lubliner zakupił wraz ks.
          Stanisławem Zarembą majątek w Olesinie Dużym koło Dębego Wielkiego.
          Byli współwłaścicielami majątku do roku 1913. W tym roku rozwiązali
          spółkę i założyli osobne księgi hipoteczne. W roku 1917 ks. Zaremba,
          odsprzedał swoją część senatorowi RP Janowi Zaglenicznemu (dziś
          istniejący dwór w Olesinie), który był dyrektorem Fabryki Cukru Brześć
          Kujawski. Folwark Olesin wówczas miał powierzchnię 188 morgów i 154
          prętów kwadratowych (ok. 105 ha 543 m²).
        </p>
        <p>
          Dobra Olesin Duży były własnością Jana i Eleonory Zaglenicznych do
          1931 r. Małżonkowie, tytuł własności dóbr przepisali na swoją córkę
          Irenę Zagleniczną Maciejewiczową (Maciejewicz), zachowując sobie prawo
          dożywotniego użytkowania.
        </p>
        <p>
          W 1941 r. Jolanta Maciejewicz wychodzi za mąż za Jerzego Gruhna rządcę
          folwarku Zofii Arkuszewskiej w Krubkach. Małżeństwo mieszka we dworze
          w Olesinie. Tak po latach wspomina ich drugi skoczek cichociemny,
          który skakał razem z Józefem Retingerem w nocy z 3 na 4 kwietnia 1944
          r. na zrzutowisko „Zegar” w Olesinie, Tadeusz Chciuk ps. „Sulima”
          „Marek Celt”:{' '}
          <i>
            „Ten pierwszy dla nas na polskiej ziemi dom – to był dwór w Olesinie
            Dużym, gmina Dębe Wielkie, powiat Mińsk Mazowiecki. Właściciele,
            młode małżeństwo: Jolanta i Jerzy Gruhnowie. Prawie pół wieku po
            dacie 4.4.44 otrzymałem piękny list od p. Jerzego z Wrocławia.
            Odnalazł mnie dzięki lekturze „Białych Kurierów”, domyślił się, że
            autor to jego gość – zrzutek sprzed lat. Odtąd byłem z tym dzielnym
            człowiekiem w stałym kontakcie; pani Jolanta, niestety już od dawna
            nie żyje. Jerzy ożenił się powtórnie ze wspaniałą Polką, która
            wychowała mu dzieci z pierwszego małżeństwa i jest podporą całej
            rodziny. Olesin Duży komuniści rozparcelowali, „dwór się wali”, jak
            mi Jerzy pisał. Pradziad Jerzego, powstaniec z roku 1863, był
            stryjem Marii Curie-Skłodowskiej, dwukrotnej laureatki Nobla.
            Odnalezienie się nasze z Jerzym po tylu latach jest dla mnie
            ogromnym przeżyciem. Byłem we Wrocławiu gościem pp. Gruhnów w 1991
            roku, ożyły wspomnienia. Niestety, Jerzy nie doczekał wydania tej
            książki; zmarł w 1994 roku”
          </i>
        </p>
        <p>
          Po II wojnie światowej dwór w Olesinie przejęło państwo, później był
          własnością gminy. Budynek z roku na rok niszczał. Dzisiaj jest
          własnością prywatną.
        </p>
        <p>
          Część majątku kupioną około 1910 r. przez Stanisława Lublinera nazwano
          Silvana. Eugenia Lublinerowa zamieszkała w Olesinie na stałe gdzie
          założyła szkołę. Była to szkoła z internatem, gdzie mieszkało
          kilkanaścioro dzieci mało zdolnych i niezamożnych, żeby nie powiedzieć
          – po prostu biednych. Utrzymanie majątku, szkoły i dzieci finansował
          Stanisław Lubliner, który był wziętym lekarzem laryngologiem. Dzieci
          kształcono metodą wprowadzoną w Polsce przez Eugenię Lublinerową i
          Dorotę Zylberową, polegającą na połączeniu prostej nauki szkolnej z
          nauką czynności manualnych. W szkole byli, więc zarówno nauczyciele
          jak rzemieślnicy, przygotowujący uczniów do wykonywania zawodu (kowal,
          ślusarz, cieśla).
        </p>
        <p>
          Silvana istniała do 1928 r. po czym została sprzedana. Zakupiło ją
          Gimnazjum Towarzystwa im. J. Zamojskiego w Warszawie, gdzie założyło
          swoje osiedle szkolne. Zajmowało ono obszar 17 ha (w tym 7 lasu, 2 ha
          łąki, 4 ha roli, 1 ha ogrodów i 6 stawów) i prowadziło gospodarstwo
          wiejskie. Budynek przeznaczony był na pobyt 40-44 uczniów. Posiadał
          kanalizację i własną elektrownię, boiska o powierzchni 4800 m
          <sup>2</sup> oraz strzelnicę i tor łuczniczy. Prowadzono tu również
          szkolenia z przysposobienia wojskowego, między innymi z musztry i
          strzelania.
        </p>
        <p>Fot. Piotr VASCO Wasilkowski, autorem tekstu Bogdan Kuć.</p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/debe-wielkie/dwor-olesin/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/debe-wielkie/dwor-olesin/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default DworOlesinPage
